import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import {format } from 'date-fns';
import { BarLoader } from "react-spinners"
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";
import { useParams } from "react-router-dom";

export default function ReporteNPS() {        
    const API_URL = 'https://api.medicionanalytic.com/api'        
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)    
    const [reportes, setRep] = useState([])
    const [enc, setE] = useState()
    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])    
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])    
    const [puntos, setPuntos] = useState([])    
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])
    const [preguntas, setPreg] = useState([])
    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])        
    const [loader, setLoad] = useState(false)
    const {handleSubmit, register, getValues, setValue} = useForm({ mode: "onBlur" });      
    let { day, denc, dsuc, dper} = useParams();
    
    useEffect(()=>{            
        setLoad(true)         
        axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{setR(res.data);setLoad(false)})
        axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })                
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then((response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
                      res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
                      if(logData.id === 49) {setPuntos(res.data)}
                      else {setSuc(res.data)}                
                  })
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then((response) => {
            let n;
            let temp;
            if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
            else { temp = response.data }
            if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
            else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}                
            setBaseEncuestas(n)
        })
        axios.get(API_URL + '/pregunta/all', {params: {client_id: logData.id}}).then((res)=>{setPreg(res.data)})
    },[])

    useEffect(()=>{
        let p = periodos.find(px => {return Number(px.id) === Number(dper)})
        let e = Basencuestas.find(ex => {return Number(ex.id) === Number(denc)})
        let s = Sucursales.find(sx => {return Number(sx.id) === Number(dsuc)})                
       if(p && e && s) {
        let d = new Date(p.periodo_inicio.replace(/-/g, '\/').replace(/T.+/, '')).setDate(day)        
        setValue('date_init', new Date(d).toISOString().slice(0, 10));
        setValue('date_fin', new Date(d).toISOString().slice(0, 10))
        setSP([p])
        setEncuestas([e])
        setE(e)
        setSPtos([s])            
       } 
    },[Sucursales, periodos, Basencuestas])
    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        let Document = dataFiltered
        Document.forEach((d) => {
            delete d.tipo_pregunta;
            delete d.punto_id;
            delete d.periodo_id;
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }    
    function Tabulada(){        
        let sum = []
        let res = respuestas
        if(userData.permiso === "admin") {res = respuestas}
        else { res = respuestas.filter(el => {              
                if(el.punto_id !== undefined && el.punto_id !== null){
                    return userData.puntos.split(",").indexOf(el.punto_id.toString()) > -1
                    }
                })
            }        
        res.filter(r => r.encuesta_id === enc.id)
        .forEach((r)=>{                          
              if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){                               
                    r.Sucursal = Sucursales.find(p => p.id === r.punto_id).nombre_sucursal
                    r.Segmento= preguntas.find(p => p.id === r.pregunta_id).segmento                                 
                if(r.tipo_pregunta === "nps") {
                    r.encuestado = reportes.find(item => item.id === Number(r.reporte_id))  && reportes.find(item => item.id === Number(r.reporte_id)).encuestado !== undefined ? reportes.find(item => item.id === Number(r.reporte_id)).encuestado : "no data" 
                    r.texto = res.find(item => item.id === Number(r.id+1)).respuesta
                    r.nivel = r.respuesta >= 9 ? "promotor" : r.respuesta <= 6 ? "detractor" : "neutro"
                    r.fecha = reportes.find(item => item.id === Number(r.reporte_id)) && reportes.find(item => item.id === Number(r.reporte_id)).created_at !== undefined ? reportes.find(item => item.id === Number(r.reporte_id)).created_at:"no data"
                }
                  sum.push(r)                
              }              
        })        
        return sum;
      }
    const getDateshort = (report) => {                
        const formattedDate = format(report, 'dd/MM/H:mm');            
        return formattedDate        
    }
    function showData(filtered) {        
        let x = filtered.filter(t => t.tipo_pregunta !== "npsna")                
        setDF(x)                
        setConsulta(true)
        setLoad(false)    
    }
    const SetFilters = (data,e) => {
        setLoad(true)
        e.preventDefault();
        let values = getValues()        
        let tab = Tabulada()        
        if(!enc) {setConsulta(false);return;}
        let temp = []                          
        let reps = reportes.filter(r => {
            if ((new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() <= new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, '')).getTime()) 
              && (new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() >= new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/,'')).getTime())) 
            {return sPuntos.some(p => { return sPeriods.some(f => { return p.id === r.sucursal && f.id === r.periodo_id }) })}        
          })
          console.log(reps)
        if(values.date_fin && values.date_init) {            
            temp =  tab.filter(el => { 
              return sPuntos.some(p => { 
                return sPeriods.some(f => { 
                  return reps.some(rep => {
                    return p.id === el.punto_id && f.id === el.periodo_id && el.encuesta_id === enc.id  && el.reporte_id === rep.id
                  })
                }) 
              }) 
            })        
        }
        else {
            temp =  tab.filter(el => {
                return sPuntos.some(p => {                
                    return sPeriods.some(f => {
                        return p.id === el.punto_id && f.id === el.periodo_id                 
                    })            
                })
            })    
        }    
    showData(temp)                        
    }    
    const handleEncuesta = (e) => {
        e.preventDefault()        
        let enc = encuestas.find(el => el.id === Number(e.target.value))        
        if(enc) {
            axios.get(API_URL+'/reporte', {params:{encuesta:Number(e.target.value)}}).then(res =>{setRep(res.data)})
            setE(enc)            
        }
    }    
    const handleCity = (e) => {  
        e.preventDefault()     
        setSPtos([])          
        let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
        setSuc(sucursales)
    }
    function getClass(val) {                        
        switch(val) {
            case "promotor": return "Fondo-V";
            case "neutro": return "Fondo-A";
            case "detractor": return "Fondo-R";
            default : return "Fondo-blank"
        }                       
    }
    useEffect (()=> {    
        setEncuestas([])          
        if(sPeriods.length > 0) {        
          let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) })})
          let temp2 = Basencuestas.filter(e => { return temp.some(j => {return (Number(j.encuesta_id) === Number(e.id) && e.tipo_encuesta === "encuesta") }) })      
          setEncuestas(temp2)
        }
      }, [sPeriods])  
      
return (<>    
    <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">                            
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}                                 
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onChange={handleEncuesta} >
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>                                                    
                            {logData.id === 49 ? <Col>
                              <Form.Select onChange={handleCity} >
                                  <option value={null}>Sin Ciudad Seleccionada</option>
                                  {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                              </Form.Select> 
                            </Col>
                            : null}
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                 options={userData.permiso === "admin" ?
                                 Sucursales   :
                                 Sucursales.filter(el => {return  userData.puntos.split(",").indexOf(el.id.toString()) > -1})}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className='m-2 mt-1 mb-1'>Fecha inicio</Form.Label>
                                <Form.Control className='rmsc' defaultValue={getValues("date_init")} type='date' {...register("date_init")} />
                            </Col>
                            <Col>
                                <Form.Label className='m-2 mt-1 mb-1'>Fecha fin</Form.Label>
                                <Form.Control className='rmsc' defaultValue={getValues("date_fin")} type='date' {...register("date_fin")} />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0" disabled = {loader}>
                                {loader ? <BarLoader className="m-2" color="#FFF" width={100} />:<>Aplicar filtros <BiSearchAlt/></> }
                            </Button>
                        </Row>
    </Form>
    {consulta ? 
    <div className="mb-4">
        <Row className="row-excel-button">
            <h3 className="p-2" style={{width:'50vw'}}>Reporte NPS</h3>         
            <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>                   
        </Row>                   
            <Table className="App-table" striped responsive>
                <thead>
                    <tr>
                        <th className="table-th Sticky-title">NPS</th>
                        <th className="table-th Sticky-title">Nivel</th>
                        <th className="table-th Sticky-title">Sucursal</th>
                        <th className="table-th Sticky-title">Encuestado</th>
                        <th className="table-th Sticky-title">Fecha/HR</th>
                        <th className="table-th expanded Sticky-title">Respuesta</th>
                    </tr>
                </thead>
                <tbody>
                    {dataFiltered.map(data => {
                    return <tr>
                            <td className={getClass(data.nivel)}>{data.respuesta}</td>
                            <td className={getClass(data.nivel)}>{data.nivel}</td>
                            <td>{data.Sucursal}</td>
                            <td>{data.encuestado}</td>
                            <td>{getDateshort(data.fecha)}</td>
                            <td>{data.texto}</td>
                        </tr>
                    })}
                </tbody>
            </Table>  
        </div>: null}
    </div>
    </>)
}